import axios from "axios";

import error                    from "_common/error";
import { updateUserDataCookie } from "_common/utils/user";
import { configs }              from "_config/index";

import { checkIsBrowser } from ".";

export const refreshTokenLogic = async originalRequest => {
	let refreshToken = originalRequest.refreshToken;
	let accessToken  = originalRequest.accessToken;
	let deviceType   = originalRequest.headers["device-type"];
	let appVersion   = originalRequest.headers["app-version"];
	let deviceId     = originalRequest.headers["device-id"];

	try {
		const headers = {
			"device-type"   : deviceType,
			"app-version"   : appVersion,
			"device-id"     : deviceId,
			"authorization" : `Bearer ${ accessToken }`
		};

		const response = await axios ( {
			method  : "post",
			url     : `${ configs.AUTH }auth/refresh`,
			data    : { refreshToken, accessToken },
			headers : headers
		} );

		const newAccessToken  = response.data.accessToken; // API returns a new access token
		const newRefreshToken = response.data.refreshToken; // API returns a new refresh token

		if ( checkIsBrowser () ) {
			updateUserDataCookie ( {
				accessToken  : newAccessToken,
				refreshToken : newRefreshToken
			} );
		}

		// Retry the failed request with the new access token
		originalRequest.headers.authorization = `Bearer ${ newAccessToken }`;
		originalRequest.accessToken           = newAccessToken;
		originalRequest.refreshToken          = newRefreshToken;

		axios.defaults.headers.common["authorization"] = `Bearer ${ newAccessToken }`;

		return axios ( originalRequest );
	} catch ( refreshError ) {
		// Handle refresh token expired error, logout the user
		if ( !checkIsBrowser () ) {
			return {
				refreshTokenError: true
			};
		}
		throw new Error ( "REFRESH_TOKEN_EXPIRED", { cause: error } );
	}
};

export const handleForcedLogout = () => {
	// Perform any custom logic you need for forced logout, e.g., redirect to logout page
	window.location.replace ( `/logout?forceLogout=${ true }&prevLocation=${ window.location.pathname }` );
	axios.defaults.headers.common = {};
};
